import { useEffect } from 'react';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import * as VKID from '@vkid/sdk';

function App() {
  const tryAuth = () => {
    VKID.Config.init({
      app: 52912111, // Идентификатор приложения.
      redirectUrl: 'https://maksimgolovanovfrontend.ru', // Адрес для перехода после авторизации.
      state: 'strokadlinnoynemenee32simvolov1234567891011blabla', // Произвольная строка состояния приложения.
      codeVerifier: '5K65XVZ0IWhzqpnAInFtefwefw23r23r32f', // Параметр в виде случайной строки. Обеспечивает защиту передаваемых данных.
      scope: 'email phone', // Список прав доступа, которые нужны приложению.
    });

    
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const deviceId = urlParams.get('device_id');
    const responseType = urlParams.get('type');

    VKID.Auth.exchangeCode(code, deviceId)
      .then((res) => {
        // console.log('УСПЕХ');
        console.log(res);
      })
      .catch(() => {
        console.log('ОШИБКА');
      });
  };

  useEffect(() => {
    tryAuth();
  }, []);
  return (
    <div className="App">
      <main>
        <div className="container">
          <div className="wrapper">
            <About />
            <Projects></Projects>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
