import React, { useEffect } from 'react';
import * as VKID from '@vkid/sdk';

const LoginPage = () => {
  useEffect(() => {
    VKID.Config.init({
      app: 52912111, // Идентификатор приложения.
      redirectUrl: 'https://maksimgolovanovfrontend.ru', // Адрес для перехода после авторизации.
      state: 'strokadlinnoynemenee32simvolov1234567891011blabla', // Произвольная строка состояния приложения.
      codeVerifier: '5K65XVZ0IWhzqpnAInFtefwefw23r23r32f', // Параметр в виде случайной строки. Обеспечивает защиту передаваемых данных.
      scope: 'email phone', // Список прав доступа, которые нужны приложению.
    });

    // Создание экземпляра кнопки.
    const oneTap = new VKID.OneTap();

    // Получение контейнера из разметки.
    const container = document.getElementById('VkIdSdkOneTap');

    // Проверка наличия кнопки в разметке.
    if (container) {
      // Отрисовка кнопки в контейнере с именем приложения APP_NAME, светлой темой и на русском языке.
      oneTap
        .render({
          container: container,
          scheme: VKID.Scheme.LIGHT,
          lang: VKID.Languages.RUS,
        })
        .on(VKID.WidgetEvents.ERROR, () => {
          console.log('Ошибка');
        }); // handleError — какой-либо обработчик ошибки.
    }

    oneTap.on(VKID.OneTapInternalEvents.LOGIN_SUCCESS, function (payload) {
      const code = payload.code;
      const deviceId = payload.device_id;

      VKID.Auth.exchangeCode(code, deviceId)
        .then((res) => {
          console.log('УСПЕХ');
          console.log(res);
        })
        .catch(() => {
          console.log('ОШИБКА');
        });
    });
  }, []);

  return (
    <div>
      <div id="VkIdSdkOneTap"></div>
    </div>
  );
};

export default LoginPage;
