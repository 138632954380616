import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AccountPage from './AccountPage';
import LoginPage from './LoginPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route index element={<App />}></Route>
      <Route path="login" element={<LoginPage />}></Route>
      <Route path="account" element={<AccountPage />}></Route>
    </Routes>
  </BrowserRouter>
);
